import "core-js/modules/es.regexp.flags.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/web.immediate.js";
import 'regenerator-runtime/runtime';
import $ from 'jquery';
import domready from 'domready';
var $addToOpenButton = $('.addto-open-button');
var $addToCloseButton = $('.addto-close-button');
var $actionsContainer = $('.actions-secondary');
window.require(['./matchHeight'], function (matchHeight) {
  var alignProducts = function alignProducts() {
    matchHeight('.products-grid .product-items .product-item-name');
    matchHeight('.products-grid .product-items .product-info');
    matchHeight('.products-grid .product-items .swatch-attribute');
    matchHeight('.products-grid .product-items .price-box');
    matchHeight('.products-grid .product-items .product-item-inner .product-item-actions .actions-primary');
    matchHeight('.products-grid .product-items .product-item-inner .product-item-actions .actions-secondary');
  };
  $(document).on('ajaxComplete', function () {
    if ($('.product-item-name')) {
      alignProducts();
    }
    onWindowResize();
    setActionButtons();
  });
  $(document).on('contentUpdated', '#layered-filter-block', function () {
    if ($('.product-item-name')) {
      alignProducts();
    }
  });
});
var updateIcons = function updateIcons(button, buttonOpacity, buttonZindex) {
  jQuery(button).css({
    'opacity': buttonOpacity,
    'zIndex': buttonZindex
  });
};
var updateIconsOnClick = function updateIconsOnClick(button, actionsOpacity, buttonOpacity, buttonZindex) {
  var closestProductItem = jQuery(button).closest('.product-item');
  updateIcons(closestProductItem.find(button), buttonOpacity, buttonZindex);
  closestProductItem.find($actionsContainer).css('opacity', actionsOpacity);
  if (actionsOpacity) {
    closestProductItem.find($actionsContainer).addClass('mobile-show-cta');
  } else {
    closestProductItem.find($actionsContainer).removeClass('mobile-show-cta');
    updateIcons(closestProductItem.find($addToOpenButton), 1, 150);
  }
};
var onWindowResize = function onWindowResize() {
  if (window.matchMedia('(max-width: 1024px)').matches) {
    updateIcons('.actions-secondary', 0, '');
    updateIcons($addToOpenButton, 1, 150);
  }
  if (window.matchMedia('(min-width: 1025px)').matches) {
    updateIcons($actionsContainer, '', '');
    updateIcons($addToOpenButton, 0, 0);
  }
};
var setActionButtons = function setActionButtons() {
  jQuery('.addto-open-button').on('click', function (event) {
    event.preventDefault();
    updateIconsOnClick(this, 1, 0, 1);
  });
  jQuery($addToCloseButton).on('click', function (event) {
    event.preventDefault();
    updateIconsOnClick(this, 0, 1, 1);
  });
};
domready(function () {
  if ($($actionsContainer).length > 0) {
    setActionButtons();
    onWindowResize();
    window.onresize = onWindowResize;
  }
});